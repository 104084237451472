export const getSymbolFromAsset = (asset) => {
  const lowerCaseAsset = asset.toLowerCase()

  switch (lowerCaseAsset) {
    case 'amdt':
      return '֏'
    case 'usd':
      return '$'
    case 'eth':
      return 'Ξ'
    case 'btc':
      return '₿'
    default:
      return ''
  }
}
